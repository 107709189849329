import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Checkbox,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Divider,
  Form,
  Icon,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CheckOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ProductReleaseLogService } from "../../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

const NewGeneratePdfForm = (props) => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const createProductRelease = useMutation(
    async (data) =>
      ProductReleaseLogService.create({
        products: props.selectedProducts,
        title: data.title,
        subtitle: data.subTitle,

      }),
    {
      onSuccess: (data) => {
        message.success("PDF Creation Started...");
        queryClient.invalidateQueries("generatedPDFLogs");
        props.onClose();
      },
      onError: (data) => {
        message.success("PDF Creation failed...");
      },
    }
  );

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <Drawer
        width={props.width || 600}
        title="Product Summary Report"
        visible={props.visible}
        onClose={props.onClose}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => createProductRelease.mutate(values)}
          onReset={onReset}
          initialValues={
            props.existing
              ? {
                  ...props.existing,
                  sitemap_urls: props.existing?.sitemap_urls.join("\n"),
                }
              : null
          }
          style={{ padding: "20px" }}
        >
          <Form.Item label="Title" name="title" required>
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item label="Sub Title" name="subTitle" required>
            <Input placeholder="Sub Title" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "20px" }}
              loading={createProductRelease.isLoading}
              disabled={createProductRelease.isLoading}
            >
              Submit
            </Button>
            <Button
              //   loading={props.isLoading}
              htmlType="button"
              onClick={onReset}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default NewGeneratePdfForm;
