import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// window.less
//   .modifyVars({
//     "@primary-color": "#7FC4FD",
//     "@layout-header-background": "#001529",
//     "@border-radius-base": "10px"
//   })
//   .then(() => {
//     console.log("color changed!");
//   })
//   .catch(error => {
//     console.error(error);
//   });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
