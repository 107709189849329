import React, { useState, useEffect } from "react";
import { AdminService } from "../../services/dataService";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Slider,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Divider,
  Form,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
// import ImportView from "./importView";
// import ExportRequestView from "./exportLogsView"
// import ActiveExercise from "./activeExercises"
// import ModelRegistryView from "./modelRegistry";
import MD5 from 'crypto-js/md5';

const UserCard = ({ item, onSelect, isSelected }) => {
  return (
    <Card hoverable onClick={() => onSelect(item)}>
      <div style={{ width: "100%" }}>
        <Row>
          <Col span={18}>
            <div>
              {item.username} | {item.email}
            </div>
            <div>Role: {item.role}</div>
            {/* <div>
              <b>{item.log} </b>
            </div> */}
          </Col>
          <Col span={6}>
            <div style={{ float: "right" }}>
              {/* {item.status == "successful" || item.status == "success" ? (
                <Tag color="green" style={{ float: "right" }}>
                  Completed
                </Tag>
              ) : item.status == "running" ? (
                <Tag color="warning" style={{ float: "right" }}>
                  Running
                </Tag>
              ) : item.status == "failed" ? (
                <Tag color="error" style={{ float: "right" }}>
                  Failed
                </Tag>
              ) : null} */}
              <Popover
                placement="bottomRight"
                content={
                  <Button type="link" onClick={() => {}}>
                    Disable User
                  </Button>
                }
                trigger="click"
              >
                <Button type="link">
                  <MoreOutlined />
                </Button>
              </Popover>
            </div>
          </Col>
        </Row>
        <div>
          {/* <Tag color="blue" style={{float: "right"}}>{item.unique_exercise?.length} Exercises</Tag>
          <Tag color="blue" style={{float: "right"}}>{item.total} Sets</Tag> */}
        </div>
      </div>
    </Card>
  );
};

const NewUserDrawer = (props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  console.log("current", props.current);
  const onReset = () => {
    form.resetFields();
  };

  const newUser = useMutation(async (data) => AdminService.add_user(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      message.success("User Created!!!");
      if (props.onClose) {
        props.onClose();
      }
    },
    onError: (error) => {
      message.error("Creation Failed!!!");
    },
  });

  const onFinish = (values) => {
    values.password = MD5(values.password).toString();
    newUser.mutate({ username: values.username, password: values.password, email: values.email });
  };

  return (
    <Drawer
      width={props.width || 600}
      title="New User"
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onReset={onReset}
        initialValues={props.current || { template: "idea" }}
        style={{ padding: "20px" }}
      >
        <Form.Item label="Username" name="username" required>
          <Input placeholder="Project Name" />
        </Form.Item>

        <Form.Item label="Email" name="email" required>
          <Input placeholder="Project Name" type="email" />
        </Form.Item>

        <Form.Item label="Temporary Password" name="password" required>
          <Input.Password  />
        </Form.Item>

        {/* <Form.Item label="Generation Template" name="template" required>
            <Select
              // defaultValue={fieldDefs.map((item) => item.field)}
              showSearch
              defaultActiveFirstOption={false}
              options={TEMPLATE_OPTIONS}
            ></Select>
          </Form.Item> */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "20px" }}
            loading={props.isLoading}
            disabled={props.isLoading}
          >
            Submit
          </Button>
          <Button loading={props.isLoading} htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

const UserView = ({ data, isLoading, onSearch, onImport }) => {
  const [state, setState] = useState({});
  const users = useQuery(["users"], () => AdminService.get_users(), {
    staleTime: Infinity,
  });

  return (
    <>
      <div style={{ margin: "10px 0px" }}>
        <Row>
          <Col span={12}>
            {/* <Input.Search onSearch={onSearch}></Input.Search> */}
          </Col>
          <Col span={12}>
            <Button
              onClick={() =>
                setState((prev) => ({ ...prev, newUserFlag: true }))
              }
              type="primary"
              style={{ float: "right" }}
            >
              New User
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={8}>
          <div style={{ height: "70vh", overflowY: "scroll" }}>
            <List
              dataSource={users.data?.users ? users.data?.users : []}
              loading={users.isLoading}
              renderItem={(item, idx) => (
                <List.Item key={idx}>
                  <UserCard
                    item={item}
                    idx={idx}
                    // onSelect={(item) => updateSelected(item)}
                  />{" "}
                </List.Item>
              )}
            />
          </div>
        </Col>
        {/* <Col span={16}>
          {selected ? (
            <ImportDetails item={selected} />
          ) : (
            <Empty description="No Log Selected" />
          )}
        </Col> */}
      </Row>

      <NewUserDrawer
        visible={state.newUserFlag}
        onClose={() => setState((prev) => ({ ...prev, newUserFlag: false }))}
      />
      {/* <List
        // pagination={{
        //   onChange: (page) => {
        //     updateState((prevState) => ({
        //       ...prevState,
        //       filter: { ...state.filter, page: page },
        //     }));
        //   },
        //   total: userData.data ? userData.data.count : 0,
        //   current: state.filter.page,
        // }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={data ? data : []}
        loading={isLoading}
        renderItem={(item, idx) => (
          <List.Item key={idx}>
            <ImportRequestCard item={item} idx={idx} />{" "}
          </List.Item>
        )}
      /> */}
    </>
  );
};

export default UserView;
