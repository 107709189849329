import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Card,
  Row,
  Col,
  List,
  Tag,
  Popover,
  message,
  Switch,
  Space,
  Divider
} from "antd";
import { ProjectService } from "../../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  FilterOutlined,
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
} from "@ant-design/icons";

const ProjectItem = ({ item, onArchive, onUnArchive, onEdit }) => {
  return (
    <Link to={`/websites/${item.id}/`}>
      <Card className="file-card">
        <Row>
          <Col
            span={16}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
              {item.name}
            </div>
          </Col>
          <Col span={8}>
            <div style={{ float: "right" }}>
              <Popover
                placement="bottomRight"
                content={
                  <Space direction="vertical" split={<Divider type="horizontal" style={{margin: "4px 0px"}} />}>
                    <Button
                      type="link"
                      onClick={(e) => {
                        onEdit(item);

                        e.preventDefault();
                      }}
                    >
                      <EditOutlined />
                      Edit Project
                    </Button>
                    {item.archived ? (
                      <Button
                        type="link"
                        onClick={(e) => {
                          onUnArchive.mutate(item);

                          e.preventDefault();
                        }}
                      >
                        Unarchive Project
                      </Button>
                    ) : (
                      <Button
                        type="link"
                        onClick={(e) => {
                          onArchive.mutate(item);

                          e.preventDefault();
                        }}
                        danger
                      >
                        <DeleteOutlined />
                        Archive Project
                      </Button>
                    )}
                  </Space>
                }
                trigger="click"
              >
                <Button type="link">
                  <MoreOutlined />
                </Button>
              </Popover>
            </div>
          </Col>
        </Row>
        <Row>
          <Tag
            color="blue"
            onClick={(e) => {
              window.open(item.homepage, "_blank");
              e.preventDefault();
            }}
          >
            {item.homepage}
          </Tag>
          {item.archived ? <Tag color="red">Archived</Tag> : null}
        </Row>
      </Card>
    </Link>
  );
};

const ProjectList = (props) => {
  const [query, updateQuery] = useState("");
  const [filter, updateFilter] = useState({ page: 1, archived: false, page_size:12 });
  const queryClient = useQueryClient();

  const project = useQuery(
    ["project", query, filter],
    () => ProjectService.getAll({ search: query, ...filter }),
    {}
  );

  const onArchive = useMutation(
    async (data) => ProjectService.update(data.id, { archived: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
        message.success("Project Archived!!!");
        if (props.onClose) {
          props.onClose();
        }
      },
      onError: (error) => {
        // notification["error"]({
        //   message: "Add Failed!!!",
        //   description: JSON.stringify(error.response.data),
        // });
        message.error("Creation Failed!!!");
      },
    }
  );

  const onUnarchive = useMutation(
    async (data) => ProjectService.update(data.id, { archived: false }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
        message.success("Project Unarchived!!!");
        if (props.onClose) {
          props.onClose();
        }
      },
      onError: (error) => {
        // notification["error"]({
        //   message: "Add Failed!!!",
        //   description: JSON.stringify(error.response.data),
        // });
        message.error("Creation Failed!!!");
      },
    }
  );

  return (
    <div>
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={8}>
          <Input.Search onSearch={(e) => updateQuery(e)} />
        </Col>
        <Col span={4}>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              padding: "0px 0.5rem",
              marginTop: "0.25rem",
            }}
          >
            <span style={{ marginRight: "0.5rem" }}>Show Archived</span>

            <Switch
              value={filter.archived}
              onChange={(checked) =>
                updateFilter((prev) => ({ ...prev, archived: checked }))
              }
            />
          </div>
        </Col>
      </Row>
      <List
        pagination={{
          onChange: (page) => {
            updateFilter({ ...filter, page });
          },
          total: project.data ? project.data.count : 0,
          defaultCurrent: 1,
          pageSize: 12,
        }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        dataSource={project.data ? project.data.results : []}
        loading={project.isLoading}
        renderItem={(item, idx) => (
          <List.Item key={idx}>
            <ProjectItem
              onEdit={props.onEdit}
              item={item}
              idx={idx}
              onArchive={onArchive}
              onUnArchive={onUnarchive}
            />{" "}
          </List.Item>
        )}
      />
    </div>
  );
};

export default ProjectList;
