import { BaseCRUDService } from "./baseService";
import { axios } from "./api";

const baseUrl = process.env.REACT_APP_API_URL;

export const TableService = new BaseCRUDService("document_table");
export const InvoiceSearch = new BaseCRUDService("search_invoices");
export const SupplierSearch = new BaseCRUDService("search_supplier_docs");
export const SupplierService = new BaseCRUDService("supplier");

class ProjectCRUDService extends BaseCRUDService {
  generate(id, data) {
    return axios
      .post(`${this.base}/${id}/run_generation/`, data)
      .then((res) => res.data);
  }
}


class OtherCRUDService extends BaseCRUDService {
  updateCategory( data) {
    return axios
      .post(`${this.base}/update_category/`, data)
      .then((res) => res.data);
  }
  scrapeSitemap( data) {
    return axios
      .get(`${this.base}/scrape_sitemap/`, { params: data})
      .then((res) => res.data);
  }

  scrapeCheck(data) {
    return axios
      .post(`${this.base}/scrape_check/`, data)
      .then((res) => res.data);
  }
}

class ProductCRUDService extends BaseCRUDService {
  generate(id, data) {
    return axios
      .post(`${this.base}/${id}/run_generation/`, data)
      .then((res) => res.data);
  }

  get_filters() {
    return axios.get(`${this.base}/get_filters/`).then((res) => res.data);
  }

  generate_blog(id) {
    return axios
      .get(`${this.base}/${id}/generate_blog/`)
      .then((res) => res.data);
  }
  search(data) {
    return axios
      .get(`${this.base}/search/`, { params: data })
      .then((res) => res.data);
  }

  product_vendor_logs(data) {
    return axios
      .get(`${this.base}/product_vendor_logs/`, { params: data })
      .then((res) => res.data);
  }

  get_image(data) {
    return axios
      .get(`${this.base}/get_image/`, { params: data, responseType: "blob" })
      .then((res) => res);
  }

  get_html(id, data) {
    return axios
      .get(`${this.base}/${id}/get_html/`, { params: data })
      .then((res) => res.data);
  }
}

class AdminCRUDService_ extends BaseCRUDService {
  download_supplier_tables(id, params) {
    return axios
      .get(`${this.base}/${id}/download_supplier_tables/`, {
        params: params,
        responseType: "blob",
      })
      .then((res) => res);
  }
  add_user(data) {
    return axios
      .post(`${this.base}/users/add_user/`, data)
      .then((res) => res.data);
  }

  get_users(params) {
    return axios
      .get(`${this.base}/users/get_users/`, { params: params })
      .then((res) => res.data);
  }

  update_password(id, data) {
    return axios
      .post(`${this.base}/users/${id}/reset_password/`, data)
      .then((res) => res.data);
  }

  disable_user(data) {
    return axios
      .post(`${this.base}/users/get_users/`, data)
      .then((res) => res.data);
  }

  enable_user(data) {
    return axios
      .post(`${this.base}/users/get_users/`, data)
      .then((res) => res.data);
  }
}

class DocumentUploadCRUDService extends BaseCRUDService {
  upload(data) {
    return axios
      .put(`${baseUrl}/upload/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }
  get_pdf_blob(id) {
    return axios
      .get(`${this.base}/${id}/download/`, { responseType: "blob" })
      .then((res) => res);
  }

  downloadTables(id) {
    return axios
      .get(`${this.base}/${id}/download_tables/`, { responseType: "blob" })
      .then((res) => res);
  }

  uploadTables(id, data) {
    return axios
      .post(`${this.base}/${id}/upload_tables/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }

  downloadEditableTables(id) {
    return axios
      .get(`${this.base}/${id}/download_editable_tables/`)
      .then((res) => res.data);
  }

  saveEditableTables(id, data) {
    return axios.post(`${this.base}/${id}/save_editable_tables/`, data);
  }

  mapSuppliers(data) {
    return axios.post(`${this.base}/map_suppliers/`, data);
  }

  saveEditableColumns(id, data) {
    return axios.post(`${this.base}/${id}/save_table_config/`, data);
  }

  reparseAllTables(id, data) {
    return axios.post(`${this.base}/${id}/reparse_table_columns/`, data);
  }
  try_out_entity(id, data) {
    return axios.post(`${this.base}/${id}/try_out_entity/`, data);
  }

  try_out_entity_csv(id, data) {
    return axios
      .post(`${this.base}/${id}/try_out_entity_csv/`, data, {
        responseType: "blob",
      })
      .then((res) => res);
  }

  addEntity(id, data) {
    return axios.post(`${this.base}/${id}/entity/`, data);
  }

  updateEntity(id, data) {
    return axios.patch(`${this.base}/${id}/entity/`, data);
  }

  deleteEntity(id, data) {
    return axios.post(`${this.base}/${id}/delete_entity/${data.id}/`, data);
  }
  getXHTML(id) {
    return axios.get(`${this.base}/${id}/get_xhtml/`).then((res) => res.data);
  }
  reparse(id, data) {
    return axios.post(`${this.base}/${id}/reparse/`, data);
  }
  checkStatus(id) {
    return axios.get(`${this.base}/${id}/check_status/`);
  }

  getEntityTypes(id) {
    return axios.get(`${this.base}/${id}/all_entities/`);
  }

  getGlobalEntities() {
    return axios.get(`${this.base}/global_entities/`).then((res) => res.data);
  }

  updateGlobalEntities(data) {
    return axios.post(`${this.base}/global_entities/`, data);
  }
}

class ProductReleaseLogCRUDService extends BaseCRUDService {
  get_pdf_blob(id) {
    return axios
      .get(`${this.base}/${id}/download_pdf/`, { responseType: "blob" })
      .then((res) => res);
  }
}

class SupplierDocumentUploadCRUDService extends BaseCRUDService {
  upload(data) {
    return axios
      .put(`${baseUrl}/upload/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }
  get_pdf_blob(id) {
    return axios
      .get(`${this.base}/${id}/download/`, { responseType: "blob" })
      .then((res) => res);
  }
  reparse(id, data) {
    return axios.post(`${this.base}/${id}/reparse/`, data);
  }
  checkStatus(id) {
    return axios.get(`${this.base}/${id}/check_status/`);
  }
  getXHTML(id) {
    return axios.get(`${this.base}/${id}/get_xhtml/`).then((res) => res.data);
  }

  addEntity(id, data) {
    return axios.post(`${this.base}/${id}/entity/`, data);
  }

  updateEntity(id, data) {
    return axios.patch(`${this.base}/${id}/entity/`, data);
  }

  deleteEntity(id, data) {
    return axios.post(`${this.base}/${id}/delete_entity/${data.id}/`, data);
  }
}

// class CandidateUploadCRUDService extends BaseCRUDService {
//   upload(data) {
//     return axios
//       .put(`${baseUrl}/candidate_upload/`, data, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then((res) => res.data);
//   }

//   reRunPred() {
//     return axios.post(`${this.base}/rerun_pred/`).then((res) => res.data);
//   }
// }

// export const CandidateService = new CandidateUploadCRUDService("candidate");

export const DocumentService = new DocumentUploadCRUDService("document");
export const AdminService = new AdminCRUDService_("admin");
export const SupplierDocumentService = new SupplierDocumentUploadCRUDService(
  "supplier_document"
);
export const GenericExtractionConfigService = new BaseCRUDService(
  "generic_extraction_config"
);
export const GenericExtractionContractConfigService = new BaseCRUDService(
  "generic_extraction_contract_config"
);
export const ExtractionConfigService = new BaseCRUDService("extraction_config");
export const DocumentTablesService = new BaseCRUDService("document_tables");
export const ScrapeLogService = new BaseCRUDService("scraping_logs");

export const ProductReleaseLogService = new ProductReleaseLogCRUDService(
  "product_release_log"
);

export const ProjectService = new ProjectCRUDService("project");
export const ProductService = new ProductCRUDService("product");
export const OtherService = new OtherCRUDService("other");