import React, { useEffect, useState } from "react";
import { Input, Button, Form, Select, Drawer, message, Checkbox } from "antd";
import { ProjectService } from "../../../services/dataService";
import { Link } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ScrapingConfig from "./xpathConfig";
const TEMPLATE_OPTIONS = [
  { value: "idea", label: "Idea" },
  { value: "concept_generator", label: "Concept Generator" },
  { value: "project_name", label: "Project Name" },
  { value: "benefit", label: "Benefit" },
  { value: "evoke", label: "Evoke" },
  { value: "occasion", label: "Occasion" },
  { value: "use", label: "Use" },
  { value: "feature_builder", label: "Feature Builder" },
];

const NewProjectDrawer = (props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  console.log("current", props.existing);
  useEffect(() => {
    if (props.existing) {
      form.setFieldsValue({
        ...props.existing,
        sitemap_urls: props.existing?.sitemap_urls.join("\n"),
      } );
    }
  }, [props.existing]);

  const onReset = () => {
    form.resetFields();
  };

  const newProject = useMutation(async (data) => ProjectService.create(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("project");
      message.success("Project Created!!!");
      if (props.onClose) {
        props.onClose();
      }
    },
    onError: (error) => {
      // notification["error"]({
      //   message: "Add Failed!!!",
      //   description: JSON.stringify(error.response.data),
      // });
      message.error("Creation Failed!!!");
    },
  });

  const editProject = useMutation(
    async (data) => ProjectService.update(data.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
        if (props.onClose) {
          props.onClose();
        }
        message.success("Update Done!!!");
      },
      onError: (error) => {
        // notification["error"]({
        //   message: "Update Failed!!!",
        //   description: JSON.stringify(error.response.data),
        // });
        message.error("Update Failed!!!");
      },
    }
  );

  const onFinish = (values) => {
    debugger
    if (props.existing) {
      editProject.mutate({
        id: props.existing.id,
        name: values.name,
        homepage: values.homepage,
        scraping_config: values.scraping_config,
        image_config: values.image_config,
        icon_url: values.icon_url,
        cloud_scraping: values.cloud_scraping,
        filter_keywords: values.filter_keywords, 
        sitemap_urls: values.sitemap_urls.split("\n"),
      });
    } else {
      newProject.mutate({
        name: values.name,
        homepage: values.homepage,
        scraping_config: values.scraping_config,
        image_config: values.image_config,
        icon_url: values.icon_url,
        cloud_scraping: values.cloud_scraping,
        filter_keywords: values.filter_keywords, 
        sitemap_urls: values.sitemap_urls.split("\n"),
      });
    }
  };

  return (
    <Drawer
      width={props.width || 600}
      title="New Project"
      visible={props.visible}
      onClose={props.onClose}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onReset={onReset}
        initialValues={
          props.existing
            ? {
                ...props.existing,
                sitemap_urls: props.existing?.sitemap_urls.join("\n"),
              }
            : null
        }
        style={{ padding: "20px" }}
      >
        <Form.Item label="Name" name="name" required>
          <Input placeholder="Website Name" />
        </Form.Item>

        <Form.Item label="Homepage" name="homepage" required>
          <Input placeholder="Homepage" />
        </Form.Item>

        <Form.Item label="Sitemap URLs" name="sitemap_urls" required>
          <Input.TextArea placeholder="Sitemap URLs" />
        </Form.Item>

        <Form.Item label="Icon URL" name="icon_url" required>
          <Input placeholder="Icon URL" />
        </Form.Item>

        {/* <Form.Item label="Generation Template" name="template" required>
          <Select
            // defaultValue={fieldDefs.map((item) => item.field)}
            showSearch
            defaultActiveFirstOption={false}
            options={TEMPLATE_OPTIONS}
          ></Select>
        </Form.Item> */}

        <Form.Item label="Scraping config" name="scraping_config" required>
          <ScrapingConfig />
        </Form.Item>

        <Form.Item label="Images config" name="image_config" required>
          <ScrapingConfig />
        </Form.Item>

        <Form.Item label="Filter Keywords" name="filter_keywords" >
          <Select mode="tags" />
        </Form.Item>

        <Form.Item
          // label="Use Cloud Scraping"
          name="cloud_scraping"
          valuePropName="checked"
        >
          <Checkbox>Use Cloud Scraping</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "20px" }}
            loading={props.isLoading}
            disabled={props.isLoading}
          >
            Submit
          </Button>
          <Button loading={props.isLoading} htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NewProjectDrawer;
