import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  List,
  Upload,
  Drawer,
  message,
  Select,
  Typography,
} from "antd";
import { OtherService, ProjectService } from "../../services/dataService";
import { Link } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import XPathConfig from "../project/containers/xpathConfig";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ScrapeCheck = (props) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ find_by: "xpath" });
  const queryClient = useQueryClient();

  const onReset = () => {
    form.resetFields();
  };

  const projectSearch = useQuery(
    ["projectSearch", state.query],
    () => ProjectService.getAll({ search: state.query }),
    {}
  );

  const updateProjectMutation = useMutation(
    async (data) => ProjectService.update(state.selectedWebsiteId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
        message.success("Project Updated!!!");
        if (props.onClose) {
          props.onClose();
        }
      },
      onError: (error) => {
        message.error("Update Failed!!!");
      },
    }
  );

  const scrapeURL = useMutation(
    async (data) => OtherService.scrapeCheck(data),
    {
      onSuccess: () => {},
      onError: (error) => {
        message.error("Scraping Failed!!!");
      },
    }
  );

  const onFinish = (values) => {
    // console.log(state)
    // debugger

    scrapeURL.mutate({...values, cloud_scrape: state.selectedWebsite.cloud_scraping});
  };

  const updateProject = () => {
    updateProjectMutation.mutate({
      image_config: form.getFieldValue("image_config"),
      scraping_config: form.getFieldValue("scraping_config"),
      cloud_scrape: props.current?.cloud_scrape,
    });
  };

  const getFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Row gutter={48}>
        <Col span={8}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onReset={onReset}
            initialValues={props.current}
            style={{ padding: "20px" }}
          >
            <div>
              <Typography.Title level={5}>Search Vendor</Typography.Title>
            </div>
            <Select
              style={{ width: "100%", marginBottom: "20px" }}
              showSearch
              value={state.selectedWebsiteId}
              placeholder={"Search Vendor"}
              //   style={props.style}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => setState({ ...state, query: value })}
              onSelect={(websiteId) => {
                let website = projectSearch.data?.results?.filter(
                  (item) => item.id == websiteId
                )[0];
                setState((prev) => ({ ...prev, selectedWebsiteId: websiteId, selectedWebsite: website }));
                form.setFieldsValue({
                  image_config: website.image_config,
                  scraping_config: website.scraping_config,
                });
              }}
              notFoundContent={null}
              options={(projectSearch.data?.results || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />
            <Form.Item label="URL" name="url" required>
              <Input />
            </Form.Item>

            <Form.Item label="Scraping config" name="scraping_config" required>
              <XPathConfig />
            </Form.Item>

            <Form.Item label="Images config" name="image_config" required>
              <XPathConfig />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "20px" }}
                loading={scrapeURL.isLoading}
                disabled={scrapeURL.isLoading}
              >
                Scrape URL
              </Button>
              <Button
                loading={props.isLoading}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Form>

          <Button onClick={() => updateProject()}>Update Vendor Config</Button>
        </Col>
        <Col span={16}>
          <Typography.Title level={4}>
            {scrapeURL.data?.res?.page_title}
          </Typography.Title>

          <Typography.Title level={5}>
            Description: {scrapeURL.data?.res?.page_desc}
          </Typography.Title>

          <div>
            {scrapeURL.data?.res?.scraping_config?.map((item, index) => (
              <div key={index}>
                <Typography.Title level={5}>{item.name}</Typography.Title>
                <ReactMarkdown>{item.text}</ReactMarkdown>
              </div>
            ))}
          </div>

          <div>
            {scrapeURL.data?.res?.image_config?.map((item, index) => (
              <div key={index}>
                <Typography.Title level={5}>{item.name}</Typography.Title>
                <Typography.Text>{item.src}</Typography.Text>
                <br />
                <img height="200" src={item.src}></img>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ScrapeCheck;
