import React, { useState } from "react";

import LoginForm from "./signIn";
import SignUpForm from "./signUp";
import { Tabs, Row, Col } from "antd";
import Auth from "../../services/auth";
import MD5 from "crypto-js/md5";
import UpdatePasswordForm from "./updatePassword";

const { TabPane } = Tabs;

const LoginSignupPage = (props) => {
  const [flags, updateFlags] = useState({ activeTab: "1" });
  const auth = new Auth();

  const isResetFlag = props.user?.reset_flag;
  const userId = props.user?.id;

  const login = (username, password) => {
    password = MD5(password).toString();

    return auth.login(username, password).then(() => {
      props.history.replace({ pathname: "/" });
    });
  };

  const onPasswordReset = () => {
    return auth.logout()
  };

  const signUp = (
    first_name,
    last_name,
    username,
    password,
    password2,
    email,
    role
  ) => {
    return auth.signup(
      first_name,
      last_name,
      username,
      password,
      password2,
      email,
      role
    );
  };

  return (
    <React.Fragment>
      <div
        style={{ maxWidth: "500px", margin: "100px auto", padding: "0px 25px" }}
      >
        <Row justify="center" align="middle" style={{ paddingBottom: "4rem" }}>
          <img width={220} src="/main-logo.png" alt="image" />
        </Row>
        <Tabs
          value={flags.activeTab}
          onChange={(activeTab) => updateFlags({ ...flags, activeTab })}
        >
          {isResetFlag ? (
            <TabPane tab="Reset Password" key="1">
              <UpdatePasswordForm userId={userId} onPasswordReset={onPasswordReset}></UpdatePasswordForm>
            </TabPane>
          ) : (
            <TabPane tab="Log In" key="1">
              <LoginForm onSubmit={login} />
            </TabPane>
          )}

          {/* <TabPane tab="Sign Up" key="2">
            <SignUpForm onSubmit={signUp} />
          </TabPane> */}
        </Tabs>
      </div>
    </React.Fragment>
  );
};

export default LoginSignupPage;
