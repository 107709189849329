import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Checkbox,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Divider,
  Form,
  Icon,
  Select,
  InputNumber,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ProductService, ProjectService } from "../../../services/dataService";

import createPersistedState from "use-persisted-state";

const useSearchState = createPersistedState("productDetails");

const MOMENTJS_FORMAT = "YYYY-MM-DD";

// const getColumns = ({ website_id }) => {};
const columns = [
  {
    title: "Title",
    dataIndex: "page_title",
    key: "page_title",
    render: (text, item) => <Link to={`/product/${item.id}/`}>{text}</Link>,
  },
  {
    title: "Added On",
    dataIndex: "added_on",
    key: "added_on",
    render: (text) => moment(text).format(MOMENTJS_FORMAT),
  },
  {
    title: "Link",
    dataIndex: "url",
    render: (text) => (
      <a href={text} target="_blank" style={{ color: "#1890ff" }}>
        Link
      </a>
    ),
  },
  {
    title: "Scraped",
    dataIndex: "scraping_status",
    key: "scraping_status",
    render: (text) => (
      <Tag
        color={
          text == "SUCCESS" ? "green" : text == "FAILED" ? "red" : "yellow"
        }
      >
        {text}
      </Tag>
    ),
  },
  {
    title: "# Scraped Fields",
    dataIndex: "scraped_fields",
    key: "scraped_fields",
    render: (scraped_fields) =>
      scraped_fields?.filter((item) => item.text).length,
  },
  // {
  //   title: "Tags",
  //   key: "tags",
  //   dataIndex: "tags",
  //   render: (_, { tags }) => (
  //     <>
  //       {tags.map((tag) => {
  //         let color = tag.length > 5 ? "geekblue" : "green";
  //         if (tag === "loser") {
  //           color = "volcano";
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </>
  //   ),
  // },
  // {
  //   title: "Action",
  //   key: "action",
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <a>Invite {record.name}</a>
  //       <a>Delete</a>
  //     </Space>
  //   ),
  // },
];

const ProductReleasePreview = (props) => {
  const [query, updateQuery] = useState("");
  const [filter, updateFilter] = useState({ page: 1, archived: false });
  const queryClient = useQueryClient();

  // const product = useMutation(
  //   async (data) => ProductService.update(data.id, data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("project");
  //       if (props.onClose) {
  //         props.onClose();
  //       }
  //       message.success("Update Done!!!");
  //     },
  //     onError: (error) => {
  //       message.error("Update Failed!!!");
  //     },
  //   }
  // );

  // const project = useQuery(
  //   ["project", query, filter],
  //   () =>
  //     ProductService.getAll({
  //       search: query,
  //       ...filter,
  //       website: props.website,
  //     }),
  //   {}
  // );

  return (
    <div>
      {/* <Row style={{ marginBottom: "1rem" }}>
        <Col span={8}>
          <Input.Search onSearch={(e) => updateQuery(e)} />
        </Col>
      </Row> */}
      <div style={{ position: "relative" }}>
        {/* <div className="watermark">
          <img src="/main-logo.png" height="200px"></img>
        </div> */}
        <div style={{ position: "relative", zIndex: 1 }}>
          <Row>
            <div className="product-release-heading">{props.title}</div>
          </Row>
          <Row>
            <Col span={8} style={{ height: "75vh", overflowY: "auto" }} >
                <Row align="middle" justify="center">
              <Row style={{ marginBottom: "1rem", width: "100%"}} justify="center">
                {/* <Typography.Title level={5}>Product Image:</Typography.Title> */}

                {props.scrapedImages?.length > 0 ? (
                  <img
                    src={props.scrapedImages[0]?.src}
                    height="400px"
                    style={{ float: "right" }}
                  ></img>
                ) : null}
              </Row>

              <Row style={{alignItems: "center"}}>
                <div style={{"display": "relative", left: "5rem"}}>
                <div className="product-release-subheading" >Vendor</div>
                <div><img src={props.vendorUrl}></img></div>

                </div>
              </Row>
              </Row>
            </Col>
            <Col span={1}></Col>

            <Col span={12}>
              <Row>
                <div className="product-release-subheading">
                  Product Details
                </div>
                <br/>
                <div
                  className="product-release-text"
                  style={{ marginBottom: "1rem" }}
                >
                  {props.mlDetails?.split("\n").map((item) => (
                    <div>
                      {item.split(":").length > 1 ? (
                        <>
                          {" "}
                          <strong>{item.split(":")[0]} : </strong>{" "}
                          {item.split(":")[1]}
                        </>
                      ) : (
                        <>{item}</>
                      )}
                    </div>
                  ))}
                </div>
              </Row>

              <Row>
                <div className="product-release-subheading">Summary</div>
                <div className="product-release-text">{props.mlSummary}</div>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ProductReleasePreview;
