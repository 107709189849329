import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  message,
  List,
  Col,
  Row,
  Input,
  Empty,
  Tag,
  Button,
  Divider,
  Tabs,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ScrapeLogService } from "../../../services/dataService";

import createPersistedState from "use-persisted-state";

const MOMENTJS_FORMAT = "YYYY-MM-DD";

const ScrapingLogs = (props) => {
  const [state, updateState] = useState({});
  const [filter, updateFilter] = useState({
    page: 1,
    archived: false,
    blogFilter: "All",
  });
  const queryClient = useQueryClient();

  // const product = useMutation(
  //   async (data) => ProductService.update(data.id, data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("project");
  //       if (props.onClose) {
  //         props.onClose();
  //       }
  //       message.success("Update Done!!!");
  //     },
  //     onError: (error) => {
  //       message.error("Update Failed!!!");
  //     },
  //   }
  // );

  const logs = useQuery(
    ["scrapingLogs", filter, props.websiteId],
    () =>
      ScrapeLogService.getAll({
        website_id: props.websiteId,
      }),
    {}
  );

  return (
    <div>
      <Row style={{ marginBottom: "1rem" }} gutter={48}>
        {/* <Col span={8}>
          <Input.Search onSearch={(e) => updateQuery(e)} />
        </Col> */}
        <Col span={8}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 10,
            }}
            loading={logs.isLoading}
            dataSource={logs.data?.results}
            renderItem={(item) => {
              console.log(
                "item.logs.new_slugs?.length",
                item.logs.new_slugs?.length
              );
              return (
                <List.Item
                  key={item.title}
                  // actions={[
                  //   <IconText
                  //     icon={StarOutlined}
                  //     text="156"
                  //     key="list-vertical-star-o"
                  //   />,
                  //   <IconText
                  //     icon={LikeOutlined}
                  //     text="156"
                  //     key="list-vertical-like-o"
                  //   />,
                  //   <IconText
                  //     icon={MessageOutlined}
                  //     text="2"
                  //     key="list-vertical-message"
                  //   />,
                  // ]}
                  // extra={
                  //   <img
                  //     width={272}
                  //     alt="logo"
                  //     src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                  //   />
                  // }
                >
                  {/* <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={<a href={item.href}>{item.title}</a>}
                  description={item.description}
                /> */}
                  {
                    <Row gutter={[36, 6]}>
                      {item.error ? (
                        <Tag color="danger">Error</Tag>
                      ) : (
                        <Tag color="green">Success</Tag>
                      )}
                      <Tag
                        color="blue"
                        onClick={() =>
                          updateState((prev) => ({
                            ...prev,
                            selectedItem: item,
                            activeKey: "3",
                          }))
                        }
                      >
                        Total URLs: {item.logs.all_urls?.length}
                      </Tag>
                      <Tag
                        color="blue"
                        onClick={() =>
                          updateState((prev) => ({
                            ...prev,
                            selectedItem: item,
                            activeKey: "3.1",
                          }))
                        }
                      >
                        All Slugs: {item.logs.all_slugs?.length}
                      </Tag>
                      <Tag
                        color="blue"
                        onClick={() =>
                          updateState((prev) => ({
                            ...prev,
                            selectedItem: item,
                            activeKey: "1",
                          }))
                        }
                      >
                        New URLs: {item.logs.new_urls?.length}
                      </Tag>
                      <Tag
                        color="blue"
                        onClick={() =>
                          updateState((prev) => ({
                            ...prev,
                            selectedItem: item,
                            activeKey: "1.1",
                          }))
                        }
                      >
                        New Slugs: {item.logs.new_slugs?.length}
                      </Tag>
                      <Tag
                        color="blue"
                        onClick={() =>
                          updateState((prev) => ({
                            ...prev,
                            selectedItem: item,
                            activeKey: "2",
                          }))
                        }
                      >
                        Deleted URLs: {item.logs.deleted_urls?.length}
                      </Tag>
                      <Tag
                        color="blue"
                        onClick={() =>
                          updateState((prev) => ({
                            ...prev,
                            selectedItem: item,
                            activeKey: "2.1",
                          }))
                        }
                      >
                        Deleted Slugs: {item.logs.deleted_slugs?.length}
                      </Tag>
                    </Row>
                  }
                  <Button
                    // type="text"
                    type="link"
                    onClick={() =>
                      updateState((prev) => ({ ...prev, selectedItem: item }))
                    }
                  >
                    <a>{moment(item.createdAt).format("YYYY-MM-DD HH:MM")}</a>
                  </Button>
                </List.Item>
              );
            }}
          />
        </Col>

        <Col span={16}>
          {state.selectedItem ? (
            <div>
              <div style={{ fontSize: "1.25em", fontWeight: 800 }}>
                Log:{" "}
                {moment(state.selectedItem.createdAt).format(
                  "YYYY-MM-DD HH:MM"
                )}
              </div>
              <Divider type="horizontal" />
              <div>
                <div style={{ fontWeight: 800 }}>Error: </div>
                {state.selectedItem?.error || "No Error"}
              </div>
              <div>
                <Tabs
                  activeKey={state.activeKey || "1"}
                  onChange={(activeKey) =>
                    updateState((prev) => ({ ...prev, activeKey: activeKey }))
                  }
                >
                  <Tabs.TabPane tab="New URLs" key="1">
                    <div style={{ height: "600px", overflowY: "auto" }}>
                      {state.selectedItem?.logs?.new_urls?.map((url) => (
                        <div>{url}</div>
                      ))}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="New Slugs" key="1.1">
                    <div style={{ height: "600px", overflowY: "auto" }}>
                      {state.selectedItem?.logs?.new_slug_urls?.map((url) => (
                        <div>{url}</div>
                      ))}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Deleted URLs" key="2">
                    <div style={{ height: "600px", overflowY: "auto" }}>
                      {state.selectedItem?.logs?.deleted_urls?.map((url) => (
                        <div>{url}</div>
                      ))}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Deleted Slugs" key="2.1">
                    <div style={{ height: "600px", overflowY: "auto" }}>
                      {state.selectedItem?.logs?.deleted_slug_urls?.map(
                        (url) => (
                          <div>{url}</div>
                        )
                      )}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="All URLs" key="3">
                    <div style={{ height: "600px", overflowY: "auto" }}>
                      {state.selectedItem?.logs?.all_urls?.map((url) => (
                        <div>{url}</div>
                      ))}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="All Slugs" key="3.1">
                    <div style={{ height: "600px", overflowY: "auto" }}>
                      {state.selectedItem?.logs?.all_slugs?.map((url) => (
                        <div>{url}</div>
                      ))}
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ScrapingLogs;
