import React, { useState } from "react";
import { Form, Input, Button, notification, Radio, message } from "antd";
import MD5 from "crypto-js/md5";
import { useMutation, useQueryClient } from "react-query";
import { AdminService } from "../../services/dataService";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UpdatePasswordForm = (props, ) => {
  const [form] = Form.useForm();
  const [loading, updateLoading] = useState(false);
  const userId = props.userId;
  const queryClient = useQueryClient();

  const updatePassword = useMutation(
    async (data) => AdminService.update_password(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        message.success("Password Reset Now!!!");
        if (props.onClose) {
          props.onClose();

        }
        if (props.onPasswordReset){
            props.onPasswordReset()
        }
      },
      onError: (error) => {
        message.error("Password Reset failed, check old password!!!");
      },
    }
  );

  const openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  const onFinish = (values) => {
    values.old_password = MD5(values.old_password).toString();
    values.new_password = MD5(values.new_password).toString();
    updatePassword.mutate({
      username: values.username,
      old_password: values.old_password,
      new_password: values.new_password,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      name="basic"
      size="large"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    //   initialValues={{ role: "foodie" }}
    >
      <Form.Item
        name="old_password"
        label="Old Password"
        rules={[{ required: true, message: "Please input your old password!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="new_password"
        label="New Password"
        rules={[{ required: true, message: "Please input your new password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm_password"
        label="Confirm Password"
        rules={[
          { required: true, message: "Please confirm your new password!" },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          loading={updatePassword.isLoading}
          type="primary"
          htmlType="submit"
          style={{ marginRight: "20px" }}
        >
          Submit
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdatePasswordForm;
