import React, { useState } from "react";
import ProjectList from "./containers/projectListView";
import NewProjectDrawer from "./containers/newProject";
import { Button, Drawer, Row, PageHeader } from "antd";

const Websites = (props) => {
  const [state, setState] = useState({});

  const toggleNewProject = () => {
    setState((prev) => ({
      ...prev,
      newProject: prev.newProject || prev.editItem ? false : true,
      editItem: null
    }));
  };

  console.log(state.editItem)
  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="Websites"
          extra={[<Button onClick={toggleNewProject}>New Wesbite</Button>]}
        ></PageHeader>
        {/* <SDSFileList /> */}
        <ProjectList onEdit={(item) => setState(prev=> ({...prev, editItem: item}))}/>
      </div>
      <NewProjectDrawer
        existing={state.editItem}
        onClose={toggleNewProject}
        width={600}
        visible={state.newProject || state.editItem}
      />
    </React.Fragment>
  );
};

export default Websites;
