import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Checkbox,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Radio,
  Form,
  Icon,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ProductService, ProjectService } from "../../../services/dataService";

import createPersistedState from "use-persisted-state";

const useSearchState = createPersistedState("productDetails");

const MOMENTJS_FORMAT = "YYYY-MM-DD";

const getColumns = ({ website_id, onSelect, onUnSelect, selectedProducts }) => {
  let selectedProductIds = (selectedProducts || []).map((item) => item.id);
  const columns = [
    {
      title: "Title",
      dataIndex: "page_title",
      key: "page_title",
      render: (text, item) => (
        <Link to={`/websites/${item?.website?.id}/product/${item?.id}/`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Added On",
      dataIndex: "added_on",
      key: "added_on",
      render: (text) => moment.unix(text).format(MOMENTJS_FORMAT),
    },
    {
      title: "Link",
      dataIndex: "url",
      render: (text) => (
        <a href={text} target="_blank" style={{ color: "#1890ff" }}>
          Link
        </a>
      ),
    },
    {
      title: "Scraped",
      dataIndex: "scraping_status",
      key: "scraping_status",
      render: (text, item) => {
        if (text == "FAILED") {
          return (
            <Popover
              trigger="hover"
              content={
                <div>
                  <p>{item.logs?.error}</p>
                </div>
              }
            >
              <Tag color="red">{text}</Tag>
            </Popover>
          );
        } else {
          return (
            <Tag
              color={
                text == "SUCCESS"
                  ? "green"
                  : text == "FAILED"
                  ? "red"
                  : "yellow"
              }
            >
              {text}
            </Tag>
          );
        }
      },
    },
    {
      title: "# Scraped Fields",
      dataIndex: "scraped_fields",
      key: "scraped_fields",
      render: (scraped_fields) => {
        return typeof scraped_fields == Array
          ? scraped_fields?.filter((item) => item.text).length
          : 0;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        if (selectedProductIds.indexOf(record.id) < 0) {
          return (
            <Space size="middle">
              <Button type="link" onClick={() => onSelect(record)}>
                Select
              </Button>
            </Space>
          );
        } else {
          return (
            <Space size="middle">
              <Button type="link" danger onClick={() => onUnSelect(record)}>
                Un-Select
              </Button>
            </Space>
          );
        }
      },
    },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";
    //         if (tag === "loser") {
    //           color = "volcano";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];
  return columns;
};

const ProductTable = (props) => {
  const [query, updateQuery] = useState("");
  const [filter, updateFilter] = useState({
    page: 1,
    archived: false,
    blogFilter: "All",
    scrapingStatus: "All",
  });
  const queryClient = useQueryClient();

  // const product = useMutation(
  //   async (data) => ProductService.update(data.id, data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("project");
  //       if (props.onClose) {
  //         props.onClose();
  //       }
  //       message.success("Update Done!!!");
  //     },
  //     onError: (error) => {
  //       message.error("Update Failed!!!");
  //     },
  //   }
  // );

  const project = useQuery(
    ["productList", query, filter, props.websiteId],
    () =>
      ProductService.search({
        search: query,
        ...filter,
        websites: (filter.websites || []).join("|"),
        category: (filter.category || []).join("|"),
        sub_category: (filter.sub_category || []).join("|"),
        product_class: (filter.product_class || []).join("|"),
        added_on_start: filter.startDateStr,
        added_on_end: filter.endDateStr,
        scraping_status: filter.scrapingStatus,
        blog_generated: filter.blogFilter,
        website_id: props.websiteId,
      }),
    {}
  );

  const projectFilters = useQuery(
    ["productFilters"],
    () => ProductService.get_filters({}),
    { staleTime: Infinity }
  );

  const columns = getColumns({
    onSelect: props.onSelect,
    onUnSelect: props.onUnSelect,
    selectedProducts: props.selectedProducts,
  });

  const options = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const onDateChange = (dateRange) => {
    if (dateRange) {
      let startDate = dateRange[0];
      let endDate = dateRange[1];
      updateFilter((prev) => ({
        ...prev,
        startDateStr: startDate.format("YYYYMMDD"),
        endDateStr: endDate.format("YYYYMMDD"),
        startDate: startDate,
        endDate: endDate,
      }));
    } else {
      updateFilter((prev) => ({
        ...prev,
        startDateStr: null,
        endDateStr: null,
        startDate: null,
        endDate: null,
      }));
    }
  };

  const blogOptions = [
    { label: "All", value: "All" },
    { label: "No", value: "No" },
    { label: "Yes", value: "Yes" },
  ];

  const scrapingStatusOptions = [
    { label: "All", value: "All" },
    { label: "Success", value: "SUCCESS" },
    { label: "Failed", value: "FAILED" },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={8}>
          <Input.Search onSearch={(e) => updateQuery(e)} />
        </Col>
        <Popover
          placement="bottom"
          content={
            <Row style={{ width: "500px" }}>
              <Col span={12} style={{ padding: "0px 0.5rem" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text>Category</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, category: e }))
                    }
                    options={projectFilters.data?.filters?.category?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />

                  <Typography.Text>Product Class</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, product_class: e }))
                    }
                    options={projectFilters.data?.filters?.product_class?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />
                  <Typography.Text>Added On</Typography.Text>
                  <DatePicker.RangePicker
                    value={[filter.startDate, filter.endDate]}
                    onChange={onDateChange}
                  />
                  <Typography.Text>Scraping Status</Typography.Text>
                  <Radio.Group
                    options={scrapingStatusOptions}
                    onChange={(e) =>
                      updateFilter((prev) => ({
                        ...prev,
                        scrapingStatus: e.target.value,
                      }))
                    }
                    value={filter.scrapingStatus}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Space>
              </Col>
              <Col span={12} style={{ padding: "0px 0.5rem" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Typography.Text>Sub Category</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, sub_category: e }))
                    }
                    options={projectFilters.data?.filters?.sub_category?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />
                  <Typography.Text>Vendors</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, websites: e }))
                    }
                    options={projectFilters.data?.filters?.websites?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />
                  <Typography.Text>Blog Generated</Typography.Text>
                  <Radio.Group
                    options={blogOptions}
                    onChange={(e) =>
                      updateFilter((prev) => ({
                        ...prev,
                        blogFilter: e.target.value,
                      }))
                    }
                    value={filter.blogFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Space>
              </Col>
            </Row>
          }
          trigger="click"
        >
          <Button type="outlined">
            <FilterOutlined /> Filters
          </Button>
        </Popover>
      </Row>

      <Table
        loading={project.isLoading}
        columns={columns}
        dataSource={project.data?.results || []}
        pagination={{
          page: project.data?.page,
          total: project.data?.total,
          showSizeChanger: false,
          onChange: (page) => updateFilter((prev) => ({ ...prev, page: page })),
        }}
      />
    </div>
  );
};

export default ProductTable;
