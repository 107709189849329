import React, { useState } from "react";
import {
  ProductService,
  ProjectService,
  ProductReleaseLogService,
} from "../../services/dataService";
// import PDFDisplay from "../../components/common/pdfViewer";
// import FieldEditor from "./fieldsEditor";
import GenModelForm from "./containers/products";
import ProductTable from "./containers/products";
// import NewDocument from "./containers/newDocument";
import {
  Button,
  Drawer,
  Col,
  Row,
  PageHeader,
  Spin,
  notification,
  Tabs,
  Tag,
  Popover,
  message,
} from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
// import Search from "./containers/search";
import createPersistedState from "use-persisted-state";
// import DownloadSupplierTable from ".//containers/downloadTableSupplier";
// import AssignSupplier from "./containers/assignSupplier";
import { MoreOutlined } from "@ant-design/icons";
import ProductList from "./containers/products";
import {
  GeneratedListItemView,
  FinalListItemView,
} from "./containers/listView";
import SelectedProductTable from "./containers/selectedProducts";
import moment from "moment";
import GeneratedPDFs from "./containers/listView";
import NewGeneratePdfForm from "./containers/newGeneratePdf";
import ScrapingLogs from "./containers/scrapingLogs";
const persistedState = createPersistedState("websiteState");

const ProjectDetailView = (props) => {
  const queryClient = useQueryClient();
  const [state, setState] = persistedState({ paramsFlag: true });

  const onProductsAdd = (product) => {
    setState((prev) => ({
      ...prev,
      selectedProducts: [...(prev.selectedProducts || []), product],
    }));
  };
  const onProductsRemove = (product) => {
    setState((prev) => ({
      ...prev,
      selectedProducts: (prev.selectedProducts || []).filter(
        (item) => item.id != product.id
      ),
    }));
  };
  const toggleParamsFlag = () => {
    setState((prev) => ({
      ...prev,
      paramsFlag: prev.paramsFlag ? false : true,
    }));
  };
  const projectDetail = useQuery(
    ["project", props.match.params.id],
    () => ProjectService.get(props.match.params.id),
    {}
  );

  const createProductRelease = useMutation(
    async () =>
      ProductReleaseLogService.create({
        products: state.selectedProducts,
        name: `${projectDetail.data?.name}'s Product Summary - ${moment().format(
          "YYYY-MM-DD HH:mm"
        )}`,
      }),
    {
      onSuccess: (data) => {
        message.success("PDF Creation Started...");
        queryClient.invalidateQueries("generatedPDFLogs");
      },
      onError: (data) => {
        message.success("PDF Creation failed...");
      },
    }
  );

  const saveData = useMutation(
    async (data) => ProjectService.update(props.match.params.id, data),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        // message.error("Password Reset failed, check old password!!!");
      },
    }
  );



  const showGeneratedPDF = (value) => {
    setState((prev) => ({ ...prev, genPDFDrawer: true }));
  };

  const hideGeneratedPDF = (value) => {
    setState((prev) => ({ ...prev, genPDFDrawer: false }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title={
            !projectDetail.isLoading && projectDetail.data
              ? projectDetail.data.name
              : null
          }
          subTitle={
            <Tag
              onClick={() =>
                window.open(projectDetail.data?.homepage, "_blank")
              }
            >
              {projectDetail.data?.homepage}
            </Tag>
          }
          extra={[

            <Button
              // onClick={() => createProductRelease.mutate()}
              onClick={() => setState((prev) => ({ ...prev, newRelease: true }))}
              type="primary"
            >
              Generate PDF
            </Button>,
            <Popover
              placement="bottomRight"
              content={
                <Button type="link" onClick={showGeneratedPDF}>
                  See Generated PDFs
                </Button>
              }
              trigger="click"
            >
              <Button type="link">
                <MoreOutlined />
              </Button>
            </Popover>,
          ]}
          onBack={() => window.history.back()}
        ></PageHeader>
        <GeneratedPDFs
          visible={state.genPDFDrawer}
          onClose={hideGeneratedPDF}
        ></GeneratedPDFs>
        <NewGeneratePdfForm 
        selectedProducts={state.selectedProducts}
        visible={state.newRelease}
        onClose={() => setState((prev) => ({ ...prev, newRelease: false,  genPDFDrawer: true}))}
        />
        <Row style={{ height: "800px", overflowY: "scroll" }}>
          {!projectDetail.isLoading ? (
            <div style={{ width: "100%" }}>
              <Row>
                <Tabs defaultActiveKey="generated" style={{ width: "100%" }}>
                  <Tabs.TabPane key="products" tab="Products">
                    {/* <div style={{ width: "100%" }}></div> */}
                    {/* <GeneratedListItemView
                        data={state.generatedList || []}
                        onSelect={onGeneratedListSelect}
                        onRemove={onGeneratedListRemove}
                      /> */}
                    <ProductList
                      websiteId={props.match.params.id}
                      website={projectDetail.data?.name}
                      onSelect={onProductsAdd}
                      onUnSelect={onProductsRemove}
                      selectedProducts={state.selectedProducts}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="logs" tab="Logs">
                    <ScrapingLogs websiteId={props.match.params.id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="selected" tab="Selected">
                    <SelectedProductTable
                      selectedProducts={state.selectedProducts}
                      onUnSelect={onProductsRemove}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Row>
            </div>
          ) : (
            <Spin />
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ProjectDetailView;
