import React from "react";
import { Menu, Grid } from "antd";
import { Drawer, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import {
  UserOutlined,
  FileDoneOutlined,
  GlobalOutlined,
  CodeOutlined,
  CoffeeOutlined
} from "@ant-design/icons";
import Auth from "../../services/auth";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { useBreakpoint } = Grid;

const RightMenu = ({ active }) => {
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[(active || "").replaceAll(/\//gi, "")]}
      mode="inline"
      style={{ paddingLeft: "0px" }}
    >
      <Menu.Item
        key="all_products"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "72px",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={
          <CoffeeOutlined style={{ fontSize: "24px", marginRight: "0px" }} />
        }
      >
        <Link style={{ padding: "0px" }} to="/all_products/">
          Products
        </Link>
      </Menu.Item>

      <Menu.Item
        key="websites"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "72px",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={
          <FileDoneOutlined style={{ fontSize: "24px", marginRight: "0px" }} />
        }
      >
        <Link style={{ padding: "0px" }} to="/websites/">
          Websites
        </Link>
      </Menu.Item>

      <Menu.Item
        key="admin"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "72px",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={<CodeOutlined style={{ fontSize: "24px", marginRight: "0px" }} />}
      >
        <Link style={{ padding: "0px" }} to="/admin/">
          Admin
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const NavMenu = (props) => {
  const auth = new Auth();
  return (
    <nav className="menuBar">
      <div style={{ padding: "3rem 0rem" }}>
        {/* <Avatar size={64} icon={<UserOutlined />} />
         */}
                   <img width={64} src="/small-logo.png"  alt="image" />

      </div>
      <div className="menuCon">
        <div>
          <div className="rightMenu">
            <RightMenu active={props.active} />
          </div>
        </div>
      </div>
      <Button
        onClick={() => auth.logout()}
        ghost
        type="link"
        style={{ position: "absolute", bottom: "20px", color: "white" }}
        icon={<LogoutOutlined />}
      >
        Logout
      </Button>
    </nav>
  );
};

export default NavMenu;
