import React, { Component } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import Auth from "./services/auth";
import LoginSignupPage from "./pages/signInUp";
import SiderNav from "./components/sider";
import Websites from "./pages/project";
import AdminView from "./pages/admin"
import ProductDetailView from "./pages/productDetail"
import ProjectDetails from "./pages/projectDetail";
import { QueryClient, QueryClientProvider } from "react-query";
import history from "./history";
import "./App.css";
import { Layout, notification } from "antd";
import Navbar from "./components/sider/navbar";
import Sidebar from "./components/sider/sidebar";
import AllProductView from "./pages/allProducts";
const { Header, Footer, Sider, Content } = Layout;
const queryClient = new QueryClient();

const ProtectedViews = (props) => {
  var { location } = props;
  return (
    <Layout>
      <Navbar active={location.pathname.split("/")[1]}></Navbar>
      <Sidebar active={location.pathname.split("/")[1]}></Sidebar>
      <Layout>
        <Switch>
        <Route
            exact
            path="/all_products/"
            render={({ match }) => <AllProductView match={match} />}
          />
          <Route
            exact
            path="/websites/"
            render={({ match }) => <Websites match={match} />}
          />
          <Route
            exact
            path="/admin/"
            render={({ match }) => <AdminView match={match} />}
          />
          <Route
            exact
            path="/websites/:id/"
            render={({ match }) => <ProjectDetails match={match} />}
          />
          <Route
            exact
            path="/websites/:wid/product/:id/"
            render={({ match }) => <ProductDetailView match={match} />}
          />
          {/* <Route
            exact
            path="/product/:id/"
            render={({ match }) => <ProductDetailView match={match} />}
          />
 */}

        </Switch>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const auth = new Auth();
  // console.log(auth.getUser().reset_flag)
  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Router history={history}>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={({ history, location }) =>
                  !auth.isAuthenticated() || auth.getUser().reset_flag ? (
                    <LoginSignupPage history={history} location={location} user={auth.getUser()} />
                  ) : (
                    <Redirect to="/websites" />
                  )
                }
              />
              <Route
                path={[
                  "/all_products",
                  "/websites",
                  "/product",
                  "/admin",
                ]}
                render={({ history, location }) =>
                  auth.isAuthenticated() ? (
                    <ProtectedViews history={history} location={location} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                path="*"
                render={({ location }) => (
                  <Redirect to={{ pathname: "/" }}></Redirect>
                )}
              />
            </Switch>
          </div>
        </Router>
      </>
    </QueryClientProvider>
  );
};

export default App;
