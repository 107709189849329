import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Checkbox,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Divider,
  Form,
  Icon,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CheckOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ProductReleaseLogService } from "../../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

const FinalListItemCard = ({
  item,
  idx,
  // onRemove,
  // generateMoreLike,
  // generateMoreLoading,
}) => {
  var saveData = (blob, fileName) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadPDF = useMutation(
    async () =>
      ProductReleaseLogService.get_pdf_blob(item.id),
    {
      onSuccess: (data) => {
        debugger
        // message.success("PDF Creation Started...");
        saveData(data.data, `${item.title}.pdf`)
      },
      onError: (data) => {
        message.success("PDF Creation failed...");
      },
    }
  );


  return (
    <Row key={idx} align="top">
      <Col span={16}>
        <div style={{ marginRight: "2.5rem" }}>{item.title}</div>
      </Col>
      <Col span={6}>
        <div style={{ marginRight: "2.5rem" }}><Tag color={item.status == "SUCCESS" ? "green": "yellow"}>{item.status}</Tag></div>
      </Col>
      <Col span={2}>
        <div style={{ display: "flex", float: "right" }}>
          {/* <Button type="link" icon={<CheckOutlined />}></Button> */}
          <Button
            type="link"
            disabled={item.status != "SUCCESS" }
            icon={<DownloadOutlined />}
            onClick={() => downloadPDF.mutate()}
          >
            Download
          </Button>
        </div>
      </Col>
    </Row>
  );
};

const GeneratedPDFs = (props) => {
  const [query, updateQuery] = useState("");
  const [filter, updateFilter] = useState({ page: 1, archived: false });
  const queryClient = useQueryClient();

  const generatedPDFLogs = useQuery(
    ["generatedPDFLogs", query, filter],
    () =>
      ProductReleaseLogService.getAll({
        search: query,
        ...filter,
      }),
    {}
  );

  return (
    <>
      <Drawer
        width={props.width || 600}
        title="Generated PDFs"
        visible={props.visible}
        onClose={props.onClose}
        destroyOnClose={true}
      >
        <div
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
            padding: "0rem 1.25rem",
            marginBottom: "1rem",
          }}
        >
          <Row style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            <Col span={20}>
              <Input.Search onSearch={(e) => updateQuery(e)} />
            </Col>
            {/* <Col span={4}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                padding: "0px 0.5rem",
                marginTop: "0.25rem",
              }}
            >
              <span style={{ marginRight: "0.5rem" }}>Show Archived</span>

              <Switch
                value={filter.archived}
                onChange={(checked) =>
                  updateFilter((prev) => ({ ...prev, archived: checked }))
                }
              />
            </div>
          </Col> */}
          </Row>

          <List
            dataSource={
              generatedPDFLogs.data ? generatedPDFLogs.data.results : []
            }
            loading={generatedPDFLogs.isLoading}
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 10,
            }}
            renderItem={(item, idx) => (
              <List.Item key={idx}>
                <FinalListItemCard
                  item={item}
                  idx={idx}
                  // onRemove={onRemove}
                  // generateMoreLike={generateMoreLike}
                  // generateMoreLoading={generateMoreLoading}
                />
              </List.Item>
            )}
          />
        </div>
      </Drawer>
    </>
  );
};

export default GeneratedPDFs;
