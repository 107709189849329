import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Select,
  Drawer,
  message,
  Space,
  Row,
  Col,
} from "antd";
import { ProjectService } from "../../../services/dataService";
import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";

const XPathConfig = ({ value, onChange }) => {
  const [state, setState] = useState({ find_by: "xpath" });

  const onClick = () => {
    onChange([
      ...(value || []),
      { name: state.name, value: state.value, find_by: state.find_by },
    ]);
    setState((prev) => ({ ...prev, name: "", value: "", find_by: "xpath" }));
  };

  const onDelete = (filterIdx) => {
    let filteredVal = value.filter((item, idx) => idx != filterIdx);
    onChange(filteredVal);
  };

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {(value || []).map((item, idx) => (
            <div
              style={{
                border: "1px solid #8080809e",
                padding: "0.25rem 0.5rem",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <Row>
                <Col span={10}>
                  <span style={{ fontWeight: "bold", marginRight: "0.25rem" }}>
                    Field Name:{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>{item.name}</span>
                </Col>
                <Col span={8}>
                  <span style={{ fontWeight: "bold", marginRight: "0.25rem" }}>
                    Find By:{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>{item.find_by}</span>
                </Col>
                <Col span={6}>
                  <div style={{ float: "right" }}>
                    <Button
                      type="link"
                      onClick={(e) => {
                        onDelete(idx);
                      }}
                      danger
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <span style={{ fontWeight: "bold", marginRight: "0.25rem" }}>
                  Value:{" "}
                </span>
                <span style={{ overflowWrap: "anywhere" }}>{item.value}</span>
              </Row>
            </div>
          ))}
        </Space>
      </div>
      <Space direction="vertical" style={{width: "100%"}}>
        <Row align="middle">
          <Col span={6}>
            <span className="ant-form-item">Name:</span>
          </Col>
          <Col span={18}>
            <Input
              onChange={(e) =>
                setState((prev) => ({ ...prev, name: e.target.value }))
              }
              value={state.name}
              placeholder="Field Name"
              // addonBefore="Name"
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <span className="ant-form-item">Search Value:</span>
          </Col>
          <Col span={18}>
            <Input
              onChange={(e) =>
                setState((prev) => ({ ...prev, value: e.target.value }))
              }
              value={state.value}
              placeholder="Search Value"
              // addonBefore="Name"
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <span className="ant-form-item">Find By:</span>
          </Col>
          <Col span={18}>
            <Select
              value={state.find_by}
              // defaultValue="xpath"
              style={{ width: 120 }}
              onChange={(value) =>
                setState((prev) => ({ ...prev, find_by: value }))
              }
              options={[
                { value: "class_name", label: "Class Name" },
                { value: "id", label: "#ID" },
                { value: "xpath", label: "XPath" },
              ]}
            />
          </Col>
        </Row>
        <Button disabled={!(state.name && state.value)} onClick={onClick}>
          Add
        </Button>
      </Space>
    </div>
  );
};

export default XPathConfig;
