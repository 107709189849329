import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Checkbox,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Divider,
  Form,
  Icon,
  Select,
  InputNumber,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ProductService, ProjectService } from "../../../services/dataService";

import createPersistedState from "use-persisted-state";

const useSearchState = createPersistedState("productDetails");

const MOMENTJS_FORMAT = "YYYY-MM-DD";

const getColumns = ({ website_id, onUnSelect }) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "page_title",
      key: "page_title",
      render: (text, item) => (
        <Link to={`/websites/${item?.website?.id}/product/${item?.id}/`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Added On",
      dataIndex: "added_on",
      key: "added_on",
      render: (text) => moment.unix(text).format(MOMENTJS_FORMAT),
    },
    {
      title: "Link",
      dataIndex: "url",
      render: (text) => (
        <a href={text} target="_blank" style={{ color: "#1890ff" }}>
          Link
        </a>
      ),
    },
    {
      title: "Scraped",
      dataIndex: "scraping_status",
      key: "scraping_status",
      render: (text) => (
        <Tag
          color={
            text == "SUCCESS" ? "green" : text == "FAILED" ? "red" : "yellow"
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "# Scraped Fields",
      dataIndex: "scraped_fields",
      key: "scraped_fields",
      render: (scraped_fields) =>
        scraped_fields?.filter((item) => item.text).length,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" danger onClick={() => onUnSelect(record)}>Un-Select</Button>
        </Space>
      ),
    },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";
    //         if (tag === "loser") {
    //           color = "volcano";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];
  return columns;
};

const SelectedProductTable = (props) => {
  const [query, updateQuery] = useState("");
  const [filter, updateFilter] = useState({ page: 1, archived: false });
  const queryClient = useQueryClient();

  // const product = useMutation(
  //   async (data) => ProductService.update(data.id, data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("project");
  //       if (props.onClose) {
  //         props.onClose();
  //       }
  //       message.success("Update Done!!!");
  //     },
  //     onError: (error) => {
  //       message.error("Update Failed!!!");
  //     },
  //   }
  // );

  const project = useQuery(
    ["productList", query, filter],
    () =>
      ProductService.search({
        search: query,
        ...filter,
        website: props.website,
      }),
    {}
  );

  const columns = getColumns({onUnSelect: props.onUnSelect})

  return (
    <div>
      {/* <Row style={{ marginBottom: "1rem" }}>
        <Col span={8}>
          <Input.Search onSearch={(e) => updateQuery(e)} />
        </Col>
      </Row> */}

      <Table
        // loading={project.isLoading}
        columns={columns}
        dataSource={props.selectedProducts}
        // pagination={{
        //   page: project.data?.page,
        //   total: project.data?.total,
        //   showSizeChanger: false,
        //   onChange: (page) => updateFilter((prev) => ({ ...prev, page: page })),
        // }}
      />
    </div>
  );
};

export default SelectedProductTable;
