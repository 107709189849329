import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Drawer,
  Tag,
  Popover,
  PageHeader,
  Tabs,
  notification,
  message,
  List,
  Card,
  Space,
  Checkbox,
  Table,
  Col,
  Row,
  Input,
  Empty,
  Typography,
  Radio,
  Form,
  Icon,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import {
  RightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ProductService, ProjectService } from "../../services/dataService";

import createPersistedState from "use-persisted-state";

const useSearchState = createPersistedState("productDetails");

const MOMENTJS_FORMAT = "YYYY-MM-DD";

const getColumns = ({
  website_id,
  onSelect,
  onUnSelect,
  selectedProducts,
  breakdownBy,
}) => {
  let selectedProductIds = (selectedProducts || []).map((item) => item.id);

  const columns = [
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text, item) => (
        <Link to={`/websites/${item?.website?.id}/product/${item?.id}/`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => text,
    },
    {
      title: "Sub-Category",
      dataIndex: "sub_category",
      key: "sub_category",
      render: (text) => text,
    },
    {
      title: "Product Class",
      dataIndex: "product_class",
      key: "product_class",
      render: (text) => text,
    },
    {
      title: "Added/Deleted",
      dataIndex: "is_deleted",
      key: "is_deleted",

      render: (text) =>
        text == "true" ? (
          <Tag color="red">Deleted</Tag>
        ) : (
          <Tag color="green">Added</Tag>
        ),
    },
    {
      title: "Count",
      dataIndex: "doc_count",
      key: "doc_count",
      render: (text) => text,
    },
  ];
  let selectedColumns = [];

  if (breakdownBy === "vendor") {
    selectedColumns = [columns[0], columns[4], columns[5]];
  } else if (breakdownBy === "category") {
    selectedColumns = [columns[0], columns[1], columns[4], columns[5]];
  } else if (breakdownBy === "sub_category") {
    selectedColumns = [
      columns[0],
      columns[1],
      columns[2],
      columns[4],
      columns[5],
    ];
  } else if (breakdownBy === "product_class") {
    selectedColumns = [
      columns[0],
      columns[1],
      columns[2],
      columns[3],
      columns[4],
      columns[5],
    ];
  }

  return selectedColumns;
};

const ProductVendorLogs = (props) => {
  const [query, updateQuery] = useState("");
  // add default startDate and endDate of since last 2 days
  const [filter, updateFilter] = useState({
    page: 1,
    archived: false,
    startDate: moment().subtract(2, "days"),
    endDate: moment(),
    startDateStr: moment().subtract(2, "days").format("YYYYMMDD"),
    endDateStr: moment().format("YYYYMMDD"),

    blogFilter: "All",
    scrapingStatus: "All",
  });
  const queryClient = useQueryClient();

  // const product = useMutation(
  //   async (data) => ProductService.update(data.id, data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("project");
  //       if (props.onClose) {
  //         props.onClose();
  //       }
  //       message.success("Update Done!!!");
  //     },
  //     onError: (error) => {
  //       message.error("Update Failed!!!");
  //     },
  //   }
  // );

  const project = useQuery(
    ["productVendorLog", query, filter],
    () =>
      ProductService.product_vendor_logs({
        search: query,
        ...filter,
        websites: (filter.websites || []).join("|"),
        category: (filter.category || []).join("|"),
        sub_category: (filter.sub_category || []).join("|"),
        product_class: (filter.product_class || []).join("|"),
        start_date: filter.startDateStr,
        end_date: filter.endDateStr,
        scraping_status: filter.scrapingStatus,
        blog_generated: filter.blogFilter,
        website_id: props.websiteId,
      }),
    {}
  );

  const projectFilters = useQuery(
    ["productFilters"],
    () => ProductService.get_filters({}),
    { staleTime: Infinity }
  );

  const columns = getColumns({
    onSelect: props.onSelect,
    onUnSelect: props.onUnSelect,
    selectedProducts: props.selectedProducts,
    breakdownBy: filter.breakdownBy,
  });

  const options = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const onDateChange = (dateRange) => {
    if (dateRange) {
      let startDate = dateRange[0];
      let endDate = dateRange[1];
      updateFilter((prev) => ({
        ...prev,
        startDateStr: startDate.format("YYYYMMDD"),
        endDateStr: endDate.format("YYYYMMDD"),
        startDate: startDate,
        endDate: endDate,
      }));
    } else {
      updateFilter((prev) => ({
        ...prev,
        startDateStr: null,
        endDateStr: null,
        startDate: null,
        endDate: null,
      }));
    }
  };

  const blogOptions = [
    { label: "All", value: "All" },
    { label: "No", value: "No" },
    { label: "Yes", value: "Yes" },
  ];

  const scrapingStatusOptions = [
    { label: "All", value: "All" },
    { label: "Success", value: "SUCCESS" },
    { label: "Failed", value: "FAILED" },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={8}>
          <Input.Search onSearch={(e) => updateQuery(e)} />
        </Col>
        <Popover
          placement="bottom"
          content={
            <Row style={{ width: "500px" }}>
              <Col span={12} style={{ padding: "0px 0.5rem" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {/* <Typography.Text>Category</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, category: e }))
                    }
                    options={projectFilters.data?.filters?.category?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />

                  <Typography.Text>Product Class</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, product_class: e }))
                    }
                    options={projectFilters.data?.filters?.product_class?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  /> */}
                  <Typography.Text>Added On</Typography.Text>
                  <DatePicker.RangePicker
                    value={[filter.startDate, filter.endDate]}
                    onChange={onDateChange}
                  />
                  {/* <Typography.Text>Scraping Status</Typography.Text>
                  <Radio.Group
                    options={scrapingStatusOptions}
                    onChange={(e) =>
                      updateFilter((prev) => ({
                        ...prev,
                        scrapingStatus: e.target.value,
                      }))
                    }
                    value={filter.scrapingStatus}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </Space>
              </Col>
              <Col span={12} style={{ padding: "0px 0.5rem" }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {/* <Typography.Text>Sub Category</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, sub_category: e }))
                    }
                    options={projectFilters.data?.filters?.sub_category?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  /> */}
                  <Typography.Text>Vendors</Typography.Text>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}
                    onChange={(e) =>
                      updateFilter((prev) => ({ ...prev, websites: e }))
                    }
                    options={projectFilters.data?.filters?.websites?.map(
                      (item) => ({
                        label: item,
                        value: item,
                      })
                    )}
                  />
                  {/* <Typography.Text>Blog Generated</Typography.Text>
                  <Radio.Group
                    options={blogOptions}
                    onChange={(e) =>
                      updateFilter((prev) => ({
                        ...prev,
                        blogFilter: e.target.value,
                      }))
                    }
                    value={filter.blogFilter}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </Space>
              </Col>
            </Row>
          }
          trigger="click"
        >
          <Button type="outlined">
            <FilterOutlined /> Filters
          </Button>
        </Popover>
      </Row>

      <Row align="middle" style={{ margin: "1rem 0rem" }}>
        <span style={{ marginRight: "2rem" }}>Breakdown By:</span>
        <Radio.Group
          onChange={(e) =>
            updateFilter((prev) => ({ ...prev, breakdownBy: e.target.value }))
          }
          buttonStyle="solid"
          value={filter.breakdownBy || "product_class"}
          defaultValue={"product_class"}
        >
          <Radio.Button value="vendor">Vendor</Radio.Button>
          <Radio.Button value="category">Category</Radio.Button>
          <Radio.Button value="sub_category">Sub Category</Radio.Button>
          <Radio.Button value="product_class">Product Class</Radio.Button>
        </Radio.Group>
      </Row>
      <Table
        loading={project.isLoading}
        columns={columns}
        dataSource={
          project.data?.results[
            filter.breakdownBy ? filter.breakdownBy : "product_class"
          ] || []
        }
        x
        // pagination={{
        //   page: project.data?.page,
        //   total: project.data?.total,
        //   showSizeChanger: false,
        //   onChange: (page) => updateFilter((prev) => ({ ...prev, page: page })),
        // }}
      />
    </div>
  );
};

export default ProductVendorLogs;
