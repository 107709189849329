import axios from "axios";
import history from "../history";

const baseUrl = process.env.REACT_APP_API_URL;

export default class Auth {
  login = (username, password) => {
    return axios
      .post(`${baseUrl}/login/`, {
        username: username,
        password: password,
      })
      .then(({ data }) => this.handleAuthentication(data))
      .then((authResult) => this.loadUser(authResult.access));
  };

  signup = (
    first_name,
    last_name,
    username,
    password,
    password2,
    email,
    role
  ) => {
    return axios
      .post(`${baseUrl}/register/`, {
        first_name: first_name,
        last_name: last_name,
        username: username,
        password: password,
        password2: password2,
        email: email,
        role: role,
      })
      .then((data) => this.login(username, password));
  };

  // signupVerify = (verifyOtp) => {
  //   axios.post(`${baseUrl}/auth/signup-verify`, body);
  // };

  // parses the result after authentication from URL hash
  handleAuthentication = (authResult) => {
    if (authResult && authResult.access && authResult.refresh) {
      this.setSession(authResult);
      // history.push("/");
    }
    return authResult;
  };

  // Sets user details in localStorage
  setSession = (authResult) => {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(86000 * 1000 + new Date().getTime());
    localStorage.setItem("refresh_token", authResult.refresh);
    localStorage.setItem("access_token", authResult.access);
    localStorage.setItem("expires_at", expiresAt);

    // navigate to the home route
    // history.push("/home");
  };

  loadUser = async (access) => {
    await axios
      .get(`${baseUrl}/user/`, {
        headers: { Authorization: `Bearer ${access}` },
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
      });
  };

  // removes user details from localStorage
  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user");
    history.push("/");
  };

  // checks if the user is authenticated
  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    console.log("User loggin ", new Date().getTime() < expiresAt);
    return new Date().getTime() < expiresAt;
  };

  getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
}
