import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  List,
  Upload,
  Drawer,
  message,
} from "antd";
import { OtherService } from "../../services/dataService";
import { Link } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";

const UpdateCategory = (props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const onReset = () => {
    form.resetFields();
  };

  const newDocument = useMutation(
    async (data) => OtherService.updateCategory(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("supplier");
        queryClient.invalidateQueries("supplier-document");

        message.success("Category Updated!!!");
        if (props.onClose) {
          props.onClose();
        }
      },
      onError: (error) => {
        message.error("Update Failed!!!");
      },
    }
  );

  const onFinish = (values) => {
    var formData = new FormData();
    console.log(values);
    formData.append("file", values["file"][0].originFileObj);
    debugger;
    newDocument.mutate(formData);
  };
  const getFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onReset={onReset}
      initialValues={props.current}
      style={{ padding: "20px" }}
    >
      <Form.Item
        name="file"
        rules={[
          {
            required: true,
            message: "Input CSV file",
          },
        ]}
        getValueFromEvent={getFile}
      >
        <Upload
          beforeUpload={(file) => {
            // console.log(file);
            return false;
          }}
          // onChange={handleChange}
          multiple={false}
          // listType="picture"
          // defaultFileList={state.fileList}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <div style={{margin: "1rem 0"}}>
      <span>Upload a CSV file with </span>{" "}
      <span style={{ fontFamily: "monospace", "backgroundColor": "#80808047" }}>
        Product Class,Sub Category,Category
      </span>{" "}
      <span>columns</span>
      </div>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: "20px" }}
          loading={newDocument.isLoading}
          disabled={newDocument.isLoading}
        >
          Submit
        </Button>
        <Button loading={props.isLoading} htmlType="button" onClick={onReset}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdateCategory;
