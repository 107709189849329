import React, { useEffect, useState, useMemo } from "react";
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  List,
  Upload,
  Drawer,
  message,
  Select,
  Space,
  Tabs,
  Badge,
} from "antd";
import { OtherService } from "../../services/dataService";
import { Link } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
// import * as serviceWorker from './worker';
import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax

const workerInstance = worker();

const ScrapedURLs = ({ urls, regexSearch }) => {
  const [state, setState] = useState({ loading: false });
  useEffect(() => {
    if (urls) {
      setState((prev) => ({ ...prev, loading: true }));
      // Send data to the Web Worker
      workerInstance.computeData({ urls, regexSearch });

      // Listen for the message event to receive data back from the Web Worker
      workerInstance.addEventListener("message", (event) => {
        setState((prev) => ({ ...prev, loading: false, ...event.data }));
        // Terminate the Web Worker when it's no longer needed
        // workerInstance.terminate();
      });
    }
  }, [urls, regexSearch]);

  return (
    <>
      {state.loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              tab={
                <>
                  URLs{" "}
                  <Badge
                    overflowCount={Infinity}
                    className="site-badge-count-109"
                    count={state.urlCount ? state.urlCount : 0}
                    style={{ backgroundColor: "#52c41a" }}
                  />{" "}
                </>
              }
              key="1"
            >
              <div
                style={{
                  height: "600px",
                  overflowY: "auto",
                  whiteSpace: "pre",
                }}
              >
                {/* {state.urls?.map((url, idx) => (
                  <div key={idx}>{url}</div>
                ))}{" "} */}
                {state.urls?.join("\n")}
              </div>{" "}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  Product Slugs{" "}
                  <Badge
                    overflowCount={Infinity}
                    className="site-badge-count-109"
                    count={state.slugCount ? state.slugCount : 0}
                    style={{ backgroundColor: "#52c41a" }}
                  />{" "}
                </>
              }
              key="2"
            >
              <div
                style={{
                  height: "600px",
                  overflowY: "auto",
                  whiteSpace: "pre",
                }}
              >
                {/* {state.slugs?.map((slug, idx) => (
                  <div key={idx}>{slug}</div>
                ))}{" "} */}
                {state.slugs?.join("\n")}
              </div>{" "}
            </Tabs.TabPane>
          </Tabs>
          {/* <div>
            Results Found: {state.urlCount} | Product Slugs Found:{" "}
            {state.slugCount}
          </div>
          <div style={{ height: "600px", overflowY: "auto" }}>
            {state.urls?.map((url, idx) => (
              <div key={idx}>{url}</div>
            ))}
          </div>{" "} */}
        </>
      )}
    </>
  );
};

const ScrapeSitemap = (props) => {
  const [form] = Form.useForm();
  const [state, updateState] = useState({});

  const queryClient = useQueryClient();

  const onReset = () => {
    form.resetFields();
  };

  const scrapeSitemap = useMutation(
    async (data) => OtherService.scrapeSitemap(data),
    {
      onSuccess: () => {
        if (props.onClose) {
          props.onClose();
        }
      },
      onError: (error) => {
        message.error("Request Failed!!!");
      },
    }
  );
  const onFinish = (values) => {
    var formData = new FormData();
    console.log(values);

    scrapeSitemap.mutate({ url: values.url });
  };

  const MemoScrapedURL = useMemo(
    () => (
      <ScrapedURLs
        urls={scrapeSitemap?.data?.urls}
        regexSearch={state.regexSearch}
      />
    ),
    [scrapeSitemap?.data?.urls, state.regexSearch]
  );

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onReset={onReset}
        initialValues={props.current}
        style={{ padding: "20px" }}
      >
        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: "Enter homepage url",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "20px" }}
            loading={scrapeSitemap.isLoading}
            disabled={scrapeSitemap.isLoading}
          >
            Submit
          </Button>
          <Button loading={props.isLoading} htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
        <div>
          <>
            <Space direction="vertical" style={{ minWidth: "600px" }}>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Regex Search"
                onChange={(e) =>
                  updateState((prev) => ({ ...prev, regexSearch: e }))
                }
                // onSearch={(e) =>
                //   updateState((prev) => ({ ...prev, regexSearch: e }))
                // }
              />

              {MemoScrapedURL}
            </Space>
          </>
        </div>
      </Form>
    </>
  );
};

export default ScrapeSitemap;
