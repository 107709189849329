import React, { useState, useRef } from "react";
import { ProductService, ProjectService } from "../../services/dataService";
// import PDFDisplay from "../../components/common/pdfViewer";
// import FieldEditor from "./fieldsEditor";
import GenModelForm from "./containers/details";
import ProductTable from "./containers/details";
// import NewDocument from "./containers/newDocument";
import {
  Button,
  Drawer,
  Col,
  Row,
  PageHeader,
  Spin,
  notification,
  Tabs,
  Tag,
  Popover,
  message,
  Descriptions,
  Typography,
  Empty,
} from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
// import Search from "./containers/search";
import createPersistedState from "use-persisted-state";
// import DownloadSupplierTable from ".//containers/downloadTableSupplier";
// import AssignSupplier from "./containers/assignSupplier";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";
import ProductDetail from "./containers/details";
import ReactMarkdown from "react-markdown";
import ProductReleasePreview from "./containers/productSummary";
import {
  GeneratedListItemView,
  FinalListItemView,
} from "./containers/listView";
import moment from "moment";
// const useGenState = createPersistedState("productDetail1");

const ProductDetailView = (props) => {
  const queryClient = useQueryClient();
  const [state, setState] = useState({ paramsFlag: true });
  const iframeRef = useRef(null);

  const toggleParamsFlag = () => {
    setState((prev) => ({
      ...prev,
      paramsFlag: prev.paramsFlag ? false : true,
    }));
  };
  const productDetail = useQuery(
    ["product", props.match.params.id],
    () => ProductService.get(props.match.params.id),
    {
      // staleTime: Infinity,
      enabled: state.loaded ? false : true,
      onSuccess: (data) => {
        setState((prev) => ({
          ...prev,
          blogData: {
            blog_post: data.ml_blog_post,
            social_media_post: {
              instagram: data.ml_instagram_post,
              twitter: data.ml_twitter_post,
            },
          },
          loaded: true,
        }));
      },
    }
  );
  const productImage = useQuery(
    ["product", props.match.params.id, "image"],
    () =>
      ProductService.get_image({ image: productDetail.data?.screenshot_path }),
    {
      // staleTime: Infinity,
      enabled: productDetail.isLoading ? false : true,
      onSuccess: (data) => {
        setState((prev) => ({
          ...prev,
          generatedList: data?.generated_list,
          finalList: data?.final_list,
          loaded: true,
        }));
      },
    }
  );

  const productHTML = useQuery(
    ["product", props.match.params.id, "html"],
    () => ProductService.get_html(props.match.params.id, {}),
    {
      onSuccess: (data) => {
        // setState((prev) => ({
        //   ...prev,
        //   loaded: true,
        // }));
        console.log(document.getElementById("product-page-html"));
        // document
        //   .getElementById("product-page-html")
        iframeRef.current?.contentWindow.document.write(data.html);
      },
    }
  );

  console.log(productImage.data);

  const generateBlog = useMutation(
    async () => ProductService.generate_blog(props.match.params.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("users");
        // message.success("Password Reset Now!!!");
        setState((prev) => ({ ...prev, blogData: data }));
      },
      onError: (error) => {
        // message.error("Password Reset failed, check old password!!!");
      },
    }
  );

  // const saveData = useMutation(
  //   async (data) => ProjectService.update(props.match.params.id, data),
  //   {
  //     onSuccess: (data) => {},
  //     onError: (error) => {
  //       // message.error("Password Reset failed, check old password!!!");
  //     },
  //   }
  // );

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success("Copied to Clipboard");
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title={
            !productDetail.isLoading && productDetail.data
              ? productDetail.data.page_title
              : null
          }
          subTitle={
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(productDetail.data?.url, "_blank");
              }}
            >
              Link
            </Tag>
          }
          extra={
            [
              // <Button onClick={toggleParamsFlag}>
              //   {" "}
              //   {state.paramsFlag ? "Hide Params" : "Show Params"}
              // </Button>,
              // <Button onClick={onFinish} type="primary">
              //   Generate
              // </Button>,
              // <Popover
              //   placement="bottomRight"
              //   content={
              //     <Button type="link" onClick={toggleAssignSupplier}>
              //       Assign Supplier
              //     </Button>
              //   }
              //   trigger="click"
              // >
              //   <Button type="link">
              //     <MoreOutlined />
              //   </Button>
              // </Popover>,
            ]
          }
          onBack={() => window.history.back()}
        >
          <Descriptions size="small" column={3}>
            <Descriptions.Item label="Description">
              <div style={{ color: "grey", fontStyle: "italic" }}>
                {productDetail.data?.page_desc}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Added on">
              <div style={{ color: "grey", fontStyle: "italic" }}>
                {moment(productDetail.data?.added_on).format("YYYY-MM-DD")}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Language">
              <div style={{ color: "grey", fontStyle: "italic" }}>
                {productDetail.data?.lang}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Category" style={{ color: "grey" }}>
              <div style={{ color: "grey", fontStyle: "italic" }}>
                {productDetail.data?.category?.category}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Sub-Category">
              <div style={{ color: "grey", fontStyle: "italic" }}>
                {productDetail.data?.category?.sub_category}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Product Class">
              <div style={{ color: "grey", fontStyle: "italic" }}>
                {productDetail.data?.category?.product_class}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <Row style={{ height: "800px" }}>
          {!productDetail.isLoading ? (
            <div style={{ width: "100%" }}>
              <Row>
                <Tabs defaultActiveKey="generated" style={{ width: "100%" }}>
                  <Tabs.TabPane key="productRelease" tab="Product Release">
                    {/* <div style={{ width: "100%" }}></div> */}
                    {/* <GeneratedListItemView
                        data={state.generatedList || []}
                        onSelect={onGeneratedListSelect}
                        onRemove={onGeneratedListRemove}
                      /> */}
                    {/* <ProductList  websiteId={props.match.params.id} website={productDetail.data?.name}/> */}
                    <ProductReleasePreview
                      title={productDetail.data.page_title}
                      addedOn={moment(productDetail.data?.added_on).format(
                        "YYYY-MM-DD"
                      )}
                      vendorUrl={productDetail.data?.vendor_url}
                      scrapedFields={productDetail.data?.scraped_fields}
                      scrapedImages={productDetail.data?.scraped_images}
                      mlSummary={productDetail.data?.ml_summary}
                      mlDetails={productDetail.data?.ml_details}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane key="details" tab="Details">
                    {/* <div style={{ width: "100%" }}></div> */}
                    {/* <GeneratedListItemView
                        data={state.generatedList || []}
                        onSelect={onGeneratedListSelect}
                        onRemove={onGeneratedListRemove}
                      /> */}
                    {/* <ProductList  websiteId={props.match.params.id} website={productDetail.data?.name}/> */}
                    <ProductDetail
                      scrapedFields={productDetail.data?.scraped_fields}
                      scrapedImages={productDetail.data?.scraped_images}
                      mlSummary={productDetail.data?.ml_summary}
                      mlDetails={productDetail.data?.ml_details}
                    />
                  </Tabs.TabPane>

                  <Tabs.TabPane key="screenshot" tab="Screenshot">
                    {productDetail.data?.screenshot_path ? (
                      <img
                        style={{ width: "70vw", objectFit: "contain" }}
                        src={
                          !productImage.isLoading
                            ? window.URL.createObjectURL(
                                productImage.data?.data
                              )
                            : null
                        }
                      ></img>
                    ) : (
                      <Empty description="No Screenshot Available" />
                    )}
                  </Tabs.TabPane>

                  <Tabs.TabPane key="blog_post" tab="Blog">
                    <>
                      <Button
                        loading={generateBlog.isLoading}
                        onClick={() => generateBlog.mutate()}
                      >
                        Generate Blog
                      </Button>
                      <div
                        style={{
                          height: "80vh",
                          maxWidth: "60%",
                          margin: "1rem auto",
                          padding: "0rem 1.5rem",
                          overflowY: "auto",
                        }}
                      >
                        {state.blogData?.blog_post.split("\n").map((text) => {
                          console.log(text.split("#").length - 1);
                          return text.split("#").length - 1 > 0 ? (
                            <div>
                              <Typography.Title level={text.split("#").length}>
                                {text.replace(/\#+/g, "")}
                              </Typography.Title>
                            </div>
                          ) : (
                            <Typography.Paragraph>
                              <ReactMarkdown>{text}</ReactMarkdown>
                            </Typography.Paragraph>
                          );
                        })}
                      </div>
                    </>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="social_media" tab="Social Media">
                    <div
                      style={{
                        height: "80vh",
                        maxWidth: "60%",
                        margin: "1rem auto",
                        padding: "1rem 0px",

                        overflowY: "auto",
                      }}
                    >
                      <Typography.Title level={3}>
                        Instagram{" "}
                        <Button
                          type="link"
                          onClick={() =>
                            copyToClipboard(
                              state.blogData?.social_media_post?.instagram
                            )
                          }
                        >
                          <CopyOutlined />
                        </Button>
                      </Typography.Title>
                      <Typography.Paragraph>
                        {state.blogData?.social_media_post?.instagram}
                      </Typography.Paragraph>
                      <Typography.Title level={3}>
                        Twitter{" "}
                        <Button
                          type="link"
                          onClick={() =>
                            copyToClipboard(
                              state.blogData?.social_media_post?.twitter
                            )
                          }
                        >
                          {" "}
                          <CopyOutlined />
                        </Button>
                      </Typography.Title>
                      <Typography.Paragraph>
                        {state.blogData?.social_media_post?.twitter}
                      </Typography.Paragraph>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="html" tab="HTML">
                    <iframe
                      style={{ height: "70vh", width: "70vw" }}
                      id="product-page-html"
                      ref={iframeRef}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Row>
            </div>
          ) : (
            <Spin />
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ProductDetailView;
